<template>
  <v-card class="pa-2">
    <v-form ref="form" @submit.prevent="save">
      <v-row>
        <v-col cols="12">
          <v-text-field
            filled
            hide-details="auto"
            v-model="receta.nombre"
            class="v-superdense-input"
            label="Nombre (con mayusc. y minusc.)"
            :rules="[rules.req]"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-select
            filled 
            clearable
            multiple
            hide-details="auto" 
            :rules="[rules.req]"
            v-model="receta.categorias" 
            label="Categoría del Menú"
            :items="categorias" 
            :item-text="item => item.nombre" 
            :item-value="item => item.idCategoria" 
            class="v-superdense-input"
            append-icon="mdi-graph-outline"
          ></v-select>
        </v-col>
        <v-col class="my-auto" cols="10">
          <h1 class="primary--text">Receta</h1>
        </v-col>
        <v-col cols="2"><v-btn class="ms-auto" @click.stop="addArt" small icon outlined color="primary"><v-icon>mdi-plus</v-icon></v-btn></v-col>
        <v-divider></v-divider>
        <template v-for="esc,i in receta.escandallos">
          <v-col cols="7" class="py-0"><div style="width: 100%; height: 100%; display: flex;align-items: center;">{{ articulos.filter(art => art.idArticulo == escandallos.filter(e => e.idEscandallo == esc.idEscandallo).map(a => a.idArticulo)[0]).map(a => a.nombre)[0] + " " + escandallos.filter(e => e.idEscandallo == esc.idEscandallo).map(a => a.nombre) }}</div></v-col>
          <v-col cols="1" class="py-0 justify-center"><v-text-field dense placeholder="UDS" hide-details="" @input="updateUds(i,$event)" :value="esc.uds"></v-text-field></v-col>
          <v-col cols="2" class="py-0"><div style="width: 100%; height: 100%; display: flex;align-items: center;justify-content: end;white-space: nowrap;">{{ parseFloat(pr(esc)).toFixed(2).replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") }} €</div></v-col>
          <v-col cols="2" class="py-0 justify-center text-center"><v-btn icon color="error" @click="receta.escandallos.splice(i,1);$forceUpdate()"><v-icon>mdi-close</v-icon></v-btn></v-col>
        </template>
          <v-col offset="8" style="white-space: nowrap;border-top:2px solid var(--v-primary-base)" v-if="receta.escandallos && receta.escandallos.length" cols="2" class="py-0 justify-center text-center"><div style="width: 100%; height: 100%; display: flex;align-items: center;justify-content: end;">{{ parseFloat(prTotal() || 0).toFixed(2).replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") }} €</div></v-col>
        <v-divider></v-divider>
        <v-col cols="12">
          <v-textarea
            filled
            hide-details="auto"
            v-model="receta.nota"
            label="Nota Receta"
          ></v-textarea>
        </v-col>
        <v-col cols="12">
          <v-card-actions class="d-flex">
						<v-spacer></v-spacer>
						<v-btn v-if="receta.idReceta" @click.stop="receta = {}">
							<v-icon left>mdi-close</v-icon>
                Cancelar
						</v-btn>
						<v-btn @click.stop="save" color="primary">
							<v-icon left>mdi-content-save</v-icon>
							{{ receta.idReceta ? "Actualizar" : "Crear" }}
						</v-btn>
					</v-card-actions>
        </v-col>
      </v-row>
    </v-form>
    <AddArticulo :familias="familias" :escandallos="escandallos" :articulos="articulos" ref="addArt"></AddArticulo>
  </v-card>
</template>

<script>
import { req } from "@/utils/validations.js";

export default {
  props: {
    value: Object,
    familias: Array,
    categorias: Array,
    articulos: Array,
    escandallos: Array
  },
  components:{
    AddArticulo: () => import("./AddArticulo.vue"),
},
  data() {
    return {
      default: {
        nombre : null,
      },
      receta: {
        ...this.value,
      },
      rules: {
        req,
      },
    };
  },
  methods: {
    pr(esc){
      var total = 0
      try{
        total += eval(this.escandallos.filter(e => e.idEscandallo == esc.idEscandallo).map(a => a.formula)[0].replaceAll(',','.').replaceAll('p', this.articulos.filter(art => art.idArticulo == this.escandallos.filter(e => e.idEscandallo == esc.idEscandallo).map(a => a.idArticulo)[0]).map(a => a.precio))) * esc.uds
      }
      catch(e){
        total += 0
      }
      return total
    },
    prTotal(){
      var total = 0
      this.receta.escandallos.forEach(esc => {
        try{
          total += eval(this.escandallos.filter(e => e.idEscandallo == esc.idEscandallo).map(a => a.formula)[0].replaceAll(',','.').replaceAll('p', this.articulos.filter(art => art.idArticulo == this.escandallos.filter(e => e.idEscandallo == esc.idEscandallo).map(a => a.idArticulo)[0]).map(a => a.precio))) * esc.uds
        }
        catch(e){
          total += 0
        }
      });
      return total
    },
    updateUds(i,uds){
      this.$set(this.receta.escandallos[i],'uds',uds)
      this.$forceUpdate()
    },
    async addArt(){
      const esc = await this.$refs.addArt.open();
      if (!this.receta.escandallos) this.receta.escandallos = []
      if (this.receta.escandallos.filter(a => a.idEscandallo == esc).length > 0) this.receta.escandallos.filter(a => a.idEscandallo == esc)[0].uds++
      else this.$set(this.receta.escandallos,this.receta.escandallos.length, { idEscandallo : esc, uds : 1 })
      this.$forceUpdate()
    },
    async save() {
      if (!this.$refs.form.validate()) return;
      if(!this.receta.escandallos || this.receta.escandallos.length == 0) {
        this.$root.$emit("snack", "ERROR: La receta debe tener al menos 1 artículo")
        return;
      }
      await axios({
        method: this.receta.idReceta ? "PUT" : "POST",
        url: `/recetas/${String(
          this.receta.idReceta ? this.receta.idReceta : ""
        )}`.replace(/\/+$/, ''),
        data: {
          ...this.receta,
        },
      });
      this.receta = {}
      this.$emit("reload");
    },
  },
};
</script>

<style>
</style>